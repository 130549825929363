import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Button } from "../components/Button"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"
import "../templates/MediaCentre.scss"

let SUBMENU = []

const Downloads = () => (
  <StaticQuery
    query={graphql`
      query BlogAllDownloadQuery {
        allWordpressWpDownloadCat {
          edges {
            node {
              name
              slug
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "media" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <CssGrid className="internal-page media-centre">
          <div>
            <Hero>
              <Content
                fillBackgroundWithImage
                fillBlockWithImage
                backgroundImage="about_ovolo_banner.jpg"
              >
                <h1>Downloads</h1>
              </Content>
            </Hero>
            {data.submenu.edges.map(({ node }, i) => {
              SUBMENU = node.items
            })}
            <Subnavigation items={SUBMENU} />
            <Breadcrumbs categoryName="Downloads" />
            <div className="container">
              <div className="row">
                <CssGrid>
                  <div className="col col-md-12 downloads">
                    <h2>Choose your download</h2>
                    <div className="blog-grids">
                      <CssGrid>
                        {data.allWordpressWpDownloadCat.edges.map(
                          ({ node }) => (
                            <div key={node.slug} className="grid">
                              <div className="entry-media">
                                {node.featured_media && (
                                  <Link to={`/downloads/${node.slug}`}>
                                    <Img
                                      src={
                                        node.featured_media.localFile
                                          .childImageSharp.sizes.src
                                      }
                                      sizes={
                                        node.featured_media.localFile
                                          .childImageSharp.sizes
                                      }
                                      alt="blog_image_one"
                                      className="img-fluid"
                                    />
                                  </Link>
                                )}
                              </div>
                              <div className="entry-body">
                                <h3>
                                  <Link
                                    to={`/downloads/${node.slug}`}
                                    dangerouslySetInnerHTML={{
                                      __html: node.name,
                                    }}
                                  />
                                </h3>
                              </div>
                            </div>
                          )
                        )}
                      </CssGrid>
                    </div>
                  </div>
                </CssGrid>
              </div>
            </div>
          </div>
        </CssGrid>
      </Layout>
    )}
  />
)

export default Downloads
